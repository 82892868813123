import React, { useState } from "react";
import ScrollToTop from "../ScrollToTop";
import FakeNewsSection from "../Fake News Page Components/FakeNewsSection";
import Footer2 from "../Footer Components/Footer2";
import Navbar2 from "../Nav Components/Navbar2";
import Sidebar2 from "../Sidebar2";
import FakeNewsHero from "../Fake News Page Components/FakeNewsHero";

const FakeNewsPage = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  return (
    <>
    <Sidebar2 isOpen={isOpen} toggle={toggle} />
      <ScrollToTop />
      <Navbar2 toggle={toggle} />
      <FakeNewsHero />
      <FakeNewsSection />
      <Footer2 />
    </>
  );
};
export default FakeNewsPage;
