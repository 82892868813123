import React, { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import { animateScroll as scroll, scroller } from "react-scroll";
import Navbar from "../Nav Components/Navbar";
import Sidebar from "../Sidebar";
import ScrollToTop from "../ScrollToTop";
import HeroSection from "../Home Components/HeroSection";
import InfoSection from "../Home Components/InfoSection.js/index.js";
import { homeObjOne, homeObjTwo, homeObjThree } from "../Home Components/InfoSection.js/Data";
import Services from "../Home Components/Services";
import HomeContactForm from "../Home Components/HomeContactForm"
import Footer from "../Footer Components/Footer/index";
import LogoGroupScroll from "../Home Components/LogoGroupScroll";
import TestimonialSlider from "../Home Components/Testimonials";

const Home = () => {
  const [isFilterActive, setIsFilterActive] = useState(false);
  const [buttonText, setButtonText] = useState('Switch OFF');
  const [isOpen, setIsOpen] = useState(false);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const location = useLocation();

  const toggleFilter = () => {
    setIsFilterActive(!isFilterActive);
    setButtonText(isFilterActive ? 'Switch Off' : 'Switch ON');
    setIsVideoPlaying(true);
    // Set a timer to stop the video and filter after 2 seconds
    setTimeout(() => {
      setIsVideoPlaying(false);
      setIsFilterActive(false);
      setButtonText('Switch OFF');
    }, 12000);
  };

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const sectionToScroll = params.get('scrollTo');

    if (sectionToScroll) {
      scroller.scrollTo(sectionToScroll, {
        smooth: true,
        duration: 500,
      });
    }
  }, [location.search]);

  return (
    <>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <ScrollToTop />
      <Navbar  isVideoPlaying={isVideoPlaying} toggleFilter={toggleFilter} isFilterActive={isFilterActive} buttonText={buttonText} toggle={toggle} />
      <HeroSection isFilterActive={isFilterActive}/>
      <LogoGroupScroll isFilterActive={isFilterActive}/>
      <InfoSection isFilterActive={isFilterActive} showTestimonial={false} {...homeObjOne} />
      <InfoSection isFilterActive={isFilterActive} showTestimonial={false} {...homeObjTwo} />
      <Services isFilterActive={isFilterActive} />
      <TestimonialSlider  isFilterActive={isFilterActive} />
      <HomeContactForm isFilterActive={isFilterActive} />
      <Footer isFilterActive={isFilterActive} />
    </>
  );
};

export default Home;
