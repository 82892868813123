import React from 'react';
import { Link as LinkR } from "react-router-dom";
import styled from 'styled-components';
import Raidar from '../../images/Raidar.png';
import CM from '../../images/cybermind.png';
import HCS from '../../images/HCS.jpg';
import ultra from '../../images/UltraNim.jpg'
import N8File from "../../files/NEURO8.pdf"
import {
  FaInstagram,
} from "react-icons/fa";

const SectionContainer = styled.div`
  hight: auto;
  width: auto;
  padding: 30px;
  background: linear-gradient(108deg, rgba(3, 169, 244, 1) 0%, rgba(0, 68, 119, 1) 100%);
`;


const ProjectsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  min-height: 20vh;
  margin-top: 50px;
  justify-content: flex-start; // Align items to the top

  @media screen and (min-width: 1031px) {
    flex-direction: row;
    justify-content: center; // Center items horizontally
    align-items: flex-start; // Align items to the top
  }
`;

const ProjectContainer = styled.div`
  border: 1px solid #e4e4e4;
  min-height: 500px;
  padding: 20px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
  width: 90%;
  margin: 0 auto;
  max-width: 400px;

  &:hover {
    transform: translateY(-5px);
  }

  @media screen and (min-width: 1031px) {
    width: 90%;
    max-width: 400px;
  }
`;

const ProjectHeader = styled.h2`
  font-size: 24px;
  margin-bottom: 10px;
  color: #000;
`;

const ProfileTitle = styled.p`
  font-size: 18px;
  font-weight: bold;
  color: #000;
`;

const ProjectText = styled.p`
  font-size: 16px;
  margin-bottom: 10px;
  color: #555;
  text-align: justify;
`;

const ProjectButton = styled.a`
font-size: 16px;
margin-bottom: 10px;
color: #555;
text-align: justify;
  color: #fff;
  background-color: #0096FF;
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  text-decoration: none;
  display: inline-block;
  margin-top: 5px;

  &:hover {
    background-color: #0076CC;
}
`;

const ImageContainer = styled.div`
margin-bottom: 20px;
display: flex;
justify-content: center;
`;

const Image = styled.img`
max-width: 100%;
max-height: 120px; 
`;

const ImageContainer2 = styled.div`
  margin-right: 10px; // Add some spacing between image and text
`;

const Image2 = styled.img`
  width: 90%;
  padding-left: 20px;
  max-width: 400px; // Adjust the maximum width as needed
`;

const LongContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr; // Two equal columns

  border: 1px solid #e4e4e4;
  min-height: 60px;
  padding: 20px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
  width: 60%;
  margin: 0 auto;

  &:hover {
    transform: translateY(5px);
  }

  @media screen and (max-width: 1031px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    min-height: 600px;
    border-radius: 10px;
    width: 90%;
    max-width: 400px;
  }
`;

export const PurpleBar = styled.div`
  background-color: #221F3C;  
  width: 100%;
  height: 5px;
  padding-top: 0px;
  padding-bottom: 0px;  
  margin-bottom: 20px;
  margin-top: 0px;
  display: flex;
`;

export const SocialIconLink = styled.a`
  color: #000;
  font-size: 50px;
  margin-right: 15px;
  

  &:hover {
   color: #e1306c;
}
`;

const ProjectsData = [
  {
    image: Raidar,
    name: "Raidar",
    subtitle: "AI Start Up",
    description: (<><br />AI Driven Risk Intelligence. <br/> <br />Co-founders: <br /> • Ellen Kay<br /> •  Ex Special Forces (K)<br /> • AWS Specialist (M)<br /> • Enterprise Platform<br /> • Investment and launch 2024. <br /> • DV and SC clearance<br />  <br /> <ProjectButton href={N8File} target="_blank" rel="noopener noreferrer">LEARN MORE</ProjectButton></>),
  },
  {
    image: HCS,
    name: "Hereford Cathedral School",
    subtitle: "Diploma – Human & Digital Resilience",
    description: (<><br />Hereford Cathedral School (HCS) Sixth Form Students. Diploma in ‘Human and Digital Resilience’ Certified by University of Buckingham.<br /><br />Facilitated by:<br />
                    • Ellen<br />
                    •	Shaun<br />
                    • Annasley
                    <br />
                    <br />
                    <ProjectButton href={N8File} target="_blank" rel="noopener noreferrer">LEARN MORE</ProjectButton>
                    <br />
                    <br />
                    <SocialIconLink href={"https://www.instagram.com/herefordcathedralschool/"} target="_blank" rel="noopener noreferrer" aria-label="Inst">
                      <FaInstagram />
                    </SocialIconLink></>),
  },
  {
    image: CM,
    name: "Cyber Mind",
    subtitle: "Academic Spin out",
    description: (<><br />Co-founders: <br/>• Professor Prashant Pillai (Cyber)<br/>• Ellen Kay (Mind). <br/><br /> An AI-based platform helping cyber-security professionals to detect, protect and manage stress.<br /><br /><ProjectButton href="https://www.linkedin.com/company/cybermind-technology-ltd/" target="_blank">LEARN MORE</ProjectButton></>),
  },
];

const CompanyProfiles = () => {
  return (
    <SectionContainer>
    <ProjectsContainer>
      {ProjectsData.map((project, index) => (
        <ProjectContainer key={index}>
          <ImageContainer>
          <Image src={project.image} alt={`Image ${index}`} />
          </ImageContainer>
          {/* <ProjectHeader>{project.name}</ProjectHeader> */}
          <PurpleBar/>
          <ProfileTitle>{project.subtitle}</ProfileTitle>
          <ProjectText>{project.description}</ProjectText>
          <ProjectText>{project.description2}</ProjectText>
          <ProjectText>{project.description3}</ProjectText>
        </ProjectContainer>
      ))}
    </ProjectsContainer>
    <ProjectsContainer>
    <LongContainer>
      <div>      
    <ImageContainer2>
          <Image2 src={ultra}  />
    </ImageContainer2>
    </div>
    <div>
    <ProjectHeader>UltraNIMBUS</ProjectHeader>
    <PurpleBar />
    <ProfileTitle>Fly a Drone by Thought Alone</ProfileTitle>
          <ProjectText>As a special project partner to Ultra Ltd we delivered a live trial of the UltraNIMBUS technology with US DEVCOM funded by the FTAS programme. Results published February 2024.</ProjectText>
    <ProjectText> <><ProjectButton href="/tech">LEARN MORE</ProjectButton></></ProjectText>
    </div>
    </LongContainer>
    </ProjectsContainer>
    </SectionContainer>
  );
};

export default CompanyProfiles;
