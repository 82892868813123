import React from "react";
import Training from "../../images/trainingCard.svg";
import FNews from "../../images/fakeNewsCard.svg";
import Talks from "../../images/talksCard.svg";
import Team from "../../images/teamCard.svg"
import SpecialP from "../../images/specialProjectsCard.svg"
import Meditation from "../../images/meditationCard.svg"
import {
  ServicesContainer,
  ServicesH1,
  ServicesWrapper,
  ServicesCard,
  ServicesIcon,
  ServicesH2,
  ServicesButton,
  TopPurpleBanner,
  BottomPurpleBanner
} from "./ServicesElements";

const Services = ({ isFilterActive }) => {
  return (
    <ServicesContainer isFilterActive={isFilterActive} id="services">
      <TopPurpleBanner><ServicesH1>HOW</ServicesH1></TopPurpleBanner>
      <ServicesWrapper>
        <ServicesCard>
          <ServicesIcon src={Talks} />
          <ServicesH2>Training</ServicesH2>
          <ServicesButton to="/training">Lean More</ServicesButton>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={FNews} />
          <ServicesH2>Fake News</ServicesH2>
          <ServicesButton to="/fakenews">Lean More</ServicesButton>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={Training} />
          <ServicesH2>Talks</ServicesH2>
          <ServicesButton to="/talks">Lean More</ServicesButton>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={Team} />
          <ServicesH2>Teams</ServicesH2>
          <ServicesButton to="/teams">Lean More</ServicesButton>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={SpecialP} />
          <ServicesH2>Special Projects</ServicesH2>
          <ServicesButton to="/projects">Lean More</ServicesButton>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={Meditation} />
          <ServicesH2 >Meditation</ServicesH2>
          <ServicesButton to="/meditation">Lean More</ServicesButton>
        </ServicesCard>
      </ServicesWrapper>
      <BottomPurpleBanner />
    </ServicesContainer>
  );
};

export default Services;
