import React, { useState } from "react";
import ScrollToTop from "../ScrollToTop";
import TechSection from "../Tech Page Components/TechSection";
import Footer2 from "../Footer Components/Footer2";
import Navbar2 from "../Nav Components/Navbar2";
import Sidebar2 from "../Sidebar2";
import TechHero from "../Tech Page Components/TechHero";
import TechLogoGroup from "../Tech Page Components/TechLogoGroup";

const TechPage = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  return (
    <>
     <Sidebar2 isOpen={isOpen} toggle={toggle} />
      <ScrollToTop />
      <Navbar2 toggle={toggle} />
      <TechHero />
      <TechLogoGroup />
      <TechSection />
      <Footer2 />
    </>
  );
};
export default TechPage;
