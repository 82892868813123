import React, { useState } from "react";
import ScrollToTop from "../ScrollToTop";
import MeditationSection from "../Meditation Page Components/MeditationSection";
import Footer2 from "../Footer Components/Footer2";
import Navbar2 from "../Nav Components/Navbar2";
import Sidebar2 from "../Sidebar2";
import MeditationHero from "../Meditation Page Components/MeditationHero";

const MeditationPage = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  return (
    <>
    <Sidebar2 isOpen={isOpen} toggle={toggle} />
      <ScrollToTop />
      <Navbar2 toggle={toggle} />
      <MeditationHero />
      <MeditationSection />
      <Footer2 />
    </>
  );
};
export default MeditationPage;
