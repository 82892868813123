import React, { useState, useEffect, useRef } from "react";
import Video from "../../../components/videos/video.mp4";
import logo from "./../../logos/N8Logo.png";
import {
  HeroContainer,
  HeroBg,
  VideoBg,
  HeroContent,
  HeroP,
  Img
} from "./HeroElements";

const HeroSection = ({ isFilterActive }) => {
  const videoRef = useRef(null);

  const playVideo = () => {
    if (videoRef.current && typeof videoRef.current.play === 'function') {
      videoRef.current.play();
    }
  };

  useEffect(() => {
    playVideo(); // Play the video once the component is mounted

    // Cleanup function to pause the video when the component is unmounted
    return () => {
    };
  }, []); // Empty dependency array ensures the effect runs only once after initial render

  return (
    <HeroContainer isFilterActive={isFilterActive} id="home">
      <HeroBg>
        <VideoBg
          ref={videoRef}
          playsInline
          loop
          muted
          src={Video}
          type="video/mp4"
        />
      </HeroBg>
      <HeroContent>
        <Img src={logo} />
        <HeroP>{/* Your paragraph content */}</HeroP>
      </HeroContent>
    </HeroContainer>
  );
};

export default HeroSection;
