import React from "react";
import styled from "styled-components";
import { keyframes } from "styled-components";

//Logo List 
import champneys from "../../logos/champneys.jpg"
import cybert from "../../logos/cybert.png"
import caplor from "../../logos/caplor.png"
import ambrey from "../../logos/ambrey.png"
import cisco from "../../logos/cisco.png"
import base3 from "../../logos/base3.png"
import cola from "../../logos/cola.png"
import cq from "../../logos/cq.png"
import sony from "../../logos/Sony.jpg";
import ultra from "../../logos/Ultra.png";
import serbus from "../../logos/serbus.png";
import dhl from "../../logos/dhl.png";
import dmlab from "../../logos/dmlab.png";
import dynamics from "../../logos/dynamics.png";
import ee from "../../logos/ee.png";
import pfizer from "../../logos/pfizer.png";
import microsoft from "../../logos/Microsoft.png";
import sequani from "../../logos/sequani.png";
import westfieldsfc from "../../logos/westfieldsfc.png";


// Array of logo image paths or URLs
const logoData = [
  { src: ambrey, width: 270, height: 150 },
  { src: base3, width: 200, height: 150 },
  { src: champneys, width: 350, height: 150 },
  { src: cisco, width: 150, height: 150 },
  { src: microsoft, width: 350, height: 150 },
  { src: cola, width: 150, height: 150 },
  { src: cq, width: 250, height: 150 },
  { src: cybert, width: 150, height: 150 },
  { src: sony, width: 250, height: 150 },
  { src: ultra, width: 350, height: 150 },
  { src: serbus, width: 350, height: 150 },
  { src: dhl, width: 350, height: 150 },
  { src: dmlab, width: 350, height: 150 },
  { src: caplor, width: 350, height: 150 },
  { src: dynamics, width: 270, height: 150 },
  { src: ee, width: 150, height: 150 },
  { src: pfizer, width: 200, height: 150 },
  { src: sequani, width: 200, height: 150 },
  { src: westfieldsfc, width: 350, height: 150 },
  { src: ambrey, width: 270, height: 150 },
  { src: base3, width: 200, height: 150 },
  { src: champneys, width: 350, height: 150 },
  { src: cisco, width: 150, height: 150 },
  { src: microsoft, width: 350, height: 150 },
  { src: cola, width: 150, height: 150 },
  { src: cq, width: 250, height: 150 },
  { src: cybert, width: 150, height: 150 },
  { src: sony, width: 250, height: 150 },
  { src: ultra, width: 350, height: 150 },
  { src: serbus, width: 350, height: 150 },
  { src: dhl, width: 350, height: 150 },
  { src: dmlab, width: 350, height: 150 },
  { src: caplor, width: 350, height: 150 },
  { src: dynamics, width: 270, height: 150 },
  { src: ee, width: 150, height: 150 },
  { src: pfizer, width: 200, height: 150 },
  { src: sequani, width: 200, height: 150 },
  { src: westfieldsfc, width: 350, height: 150 },
];

const scroll = keyframes`
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(-200%);
  }
`;

const LogoGroupWrapper = styled.div`
${(props) =>
  props.isFilterActive &&
  `
    background-color: rgba(, 0, 0, 0); /* Adjust the opacity and color as needed */
    filter: grayscale(100%); /* Apply grayscale filter to make it grey */
  `}
  
  padding: 20px;
  display: flex;
  overflow: hidden;
  width: auto;
`;

const LogoScroller = styled.div`
  display: flex;
  justify-content: space-between;
  animation: ${scroll} 60s linear infinite;
`;

const LogoImage = styled.img`
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  object-fit: contain;
  margin: 0 50px;
  transition: transform 0.3s ease-in-out;

  &:hover {
    transform: scale(1.2);
  }
`;

const LogoGroupScroll = ({ isFilterActive }) => {
  // Duplicate the logos to create a seamless loop
  const duplicatedLogos = [...logoData, ...logoData, ...logoData,];

  return (
    <LogoGroupWrapper isFilterActive={isFilterActive}>
      <LogoScroller>
        {duplicatedLogos.map((logo, index) => (
          <StyledLogoImage
            key={index}
            src={logo.src}
            alt={`Logo ${index + 1}`}
            width={logo.width}
            height={logo.height}
          />
        ))}
      </LogoScroller>
    </LogoGroupWrapper>
  );
};

const StyledLogoImage = styled(LogoImage)`
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
`;

export default LogoGroupScroll;
