import React from "react";
import {
  SidebarContainer,
  Icon,
  CloseIcon,
  SidebarWrapper,
  SidebarMenu,
  SidebarLink,
  Button,
} from "./SidebarElements";

const Sidebar2 = ({ isOpen, toggle }) => {
  return (
    <SidebarContainer isOpen={isOpen} onClick={toggle}>
      <Icon onClick={toggle}>
        <CloseIcon />
      </Icon>
      <SidebarWrapper>
        <SidebarMenu>
          <SidebarLink to="/" onClick={toggle}>
            Home
          </SidebarLink>
          <SidebarLink to="/tech" onClick={toggle}>
            Nimbus
          </SidebarLink>
          <SidebarLink to="/training" onClick={toggle}>
            Training
          </SidebarLink>
          <SidebarLink to="/fakenews" onClick={toggle}>
            Fake News
          </SidebarLink>
          <SidebarLink to="/talks" onClick={toggle}>
            Talks
          </SidebarLink>
          <SidebarLink to="/teams" onClick={toggle}>
            Teams
          </SidebarLink>
          <SidebarLink to="/projects" onClick={toggle}>
            Projects
          </SidebarLink>
          <SidebarLink to="/meditation" onClick={toggle}>
            Meditation
          </SidebarLink>
          <SidebarLink>
          <Button onClick={() => window.open("https://www.youtube.com/embed/OTCD5fQI8q8?autoplay=1&mute=1", "_blank", "fullscreen=yes")}> Switch Off</Button>
          </SidebarLink>
        </SidebarMenu>
      </SidebarWrapper>
    </SidebarContainer>
  );
};

export default Sidebar2;
