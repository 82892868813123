import React, { useState, useRef, useEffect } from "react";
import emailjs from "emailjs-com";
import {
  Container,
  Form,
  FormH1,
  FormButton,
  FormContent,
  FormInput,
  FormLabel,
  FromWrap,
  Text,
  SuccessMessage,
  ErrorMessage,
} from "./ContactFormElements";
import ReCAPTCHA from "react-google-recaptcha";

const ContactForm = ({ isFilterActive }) => {
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isVerified, setIsVerified] = useState(false);

  const nameInputRef = useRef();
  const emailInputRef = useRef();
  const messageInputRef = useRef();
  const recaptchaRef = useRef(null);

    const handleSubmit = async (e) => {
      e.preventDefault();
    
      // const recaptchaToken = await new Promise((resolve) => {
      //   window.grecaptcha.execute("6Lems9EoAAAAAGOeq61sZYynNa_OiUm2bJCl9I5j", { action: "submit" }).then((token) => {
      //     resolve(token);
      //   });
      // });

    // Check if reCAPTCHA is verified
    if (!isVerified) {
      setErrorMessage("Please verify reCAPTCHA.");
      return; // Exit function if reCAPTCHA is not verified
    }
    
      emailjs
        .send("n8_service_08lx9wa", "n8_template_u3i41yj", {
          name: nameInputRef.current.value,
          email: emailInputRef.current.value,
          message: messageInputRef.current.value,
        }, "w7u3cMNGFiLgD-gBx")
        .then((response) => {
          // console.log("Email sent successfully:", response);
          setSuccessMessage("Form submitted successfully!");
          setErrorMessage(null);
        })
        .catch((error) => {
          // console.error("Email send error:", error);
          setErrorMessage("Something went wrong, please try again.");
          setSuccessMessage(null);
        });
    
      nameInputRef.current.value = "";
      emailInputRef.current.value = "";
      messageInputRef.current.value = "";
    };

    const handleRecaptchaChange = (token) => {
      if (token) {
        setIsVerified(true);
        setErrorMessage(null);
      }
    };
    

  // useEffect(() => {
  //   window.grecaptcha.ready(() => {
  //     window.grecaptcha.init({
  //       sitekey: process.env.REACT_APP_SITE_KEY,
  //       size: "invisible",
  //     });
  //   });
  // }, []);

  return (
    <>
      <Container isFilterActive={isFilterActive} id="contact">
        <FromWrap>
          <FormContent>
            <Form onSubmit={handleSubmit}>
              <FormH1>Contact Us</FormH1>
              <FormLabel htmlFor="name">Name</FormLabel>
              <FormInput type="text" id="name" required ref={nameInputRef} />
              <FormLabel htmlFor="email">Email</FormLabel>
              <FormInput type="email" id="email" required ref={emailInputRef} />
              <FormLabel htmlFor="message">Message</FormLabel>
              <FormInput type="text" id="message" required ref={messageInputRef} />
              {successMessage && <SuccessMessage>{successMessage}</SuccessMessage>}
              {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
              
              <FormButton type="submit">Send</FormButton>
              <Text>By clicking 'Send' you are giving consent for the details you have provided to be processed in accordance with our privacy policy.</Text>
              <br />
              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey={"6Lems9EoAAAAAGOeq61sZYynNa_OiUm2bJCl9I5j"}
                theme="dark"
                size="compact" // Change this to 'normal', 'compact' or 'invisible'
                onChange={handleRecaptchaChange}/>
            </Form>
          </FormContent>
        </FromWrap>
      </Container>
    </>
  );
};

export default ContactForm;
