import React, { useState } from "react";
import ScrollToTop from "../ScrollToTop";
import ProjectsSection from "../Projects Page Components/ProjectsSection";
import Footer2 from "../Footer Components/Footer2";
import Navbar2 from "../Nav Components/Navbar2";
import Sidebar2 from "../Sidebar2";
import ProjectsHero from "../Projects Page Components/ProjectsHero";

const ProjectsPage = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  return (
    <>
    <Sidebar2 isOpen={isOpen} toggle={toggle} />
      <ScrollToTop />
      <Navbar2 toggle={toggle} />
      <ProjectsHero />
      <ProjectsSection />
      <Footer2 />
    </>
  );
};
export default ProjectsPage;
