import React from "react";
import styled from "styled-components";
import NimbusPdf from "../../files/Nimbus.pdf";
import NimbusImage from "../../logos/UltraNimbus.jpg";
import SoldierPdf from "../../files/SoldierMagazine.pdf";
import SoldierImage from "../../logos/SoldierMag.png";
import Trial from "../../images/trial.png"

const Container = styled.div`
background: linear-gradient(108deg, rgba(3, 169, 244, 1) 0%, rgba(0, 68, 119, 1) 100%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 100px; /* Adjust the padding for mobile and desktop */
  padding-bottom: 100px;
  position: relative;
  z-index: 1;
`;

const Title = styled.h1`
  font-size: 24px; /* Adjust the font size for mobile */
  margin-top: 20px; /* Add margin as needed */
  text-align: center;
  color: #000;
`;

const BannerTitle = styled.h1`
  font-size: 24px; /* Adjust the font size for mobile */
  margin-top: 20px; /* Add margin as needed */
  text-align: center;
  color: #fff;
`;

const Video = styled.iframe`
  width: 80%; /* Adjust the width for desktop */
  max-width: 800px; /* Set a max-width for larger screens */
  height: 50vh; /* Adjust the height for both mobile and desktop */
  margin: 20px 0; /* Add margin as needed */
  border-radius: 10px;
`;

const ImageLink = styled.a`
  margin-top: 16px;
  text-decoration: none;
  color: #000;
  font-weight: 700;
  font-size: 20px; /* Adjust the font size for mobile and desktop */
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: transform 0.2s;

  &:hover {
    transform: scale(1.1);
  }
`;

export const PurpleBanner = styled.div`
  background-color: #221F3C;  
  width: 100%;
  padding-top: 80px;
  padding-bottom: 80px;  
  margin-bottom: 150px;
  margin-top: 150px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 960px) {
    flex-direction: column;
  }
`;
const PairContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: auto; /* Add margin for spacing between pairs */
  
  @media (max-width: 960px) {
    margin: 50px;
  }
`;

const PairText = styled.div`
  margin-top: 8px; /* Reduce margin between image and text */
  color: #fff;
`;

const Image = styled.img`
  width: 100%; /* Adjust image width */
  max-width: 300px; /* Set max-width to control larger screens */
  height: auto; /* Maintain aspect ratio */
  margin-bottom: 10px;
  border-radius: 10px;
`;

const TechSection = () => {
  return (
    <Container>
      <Title>Specialist Defence and Security Conference UK (SDSC-UK formerly 3CDSE)</Title>
      <Video
        src="https://www.youtube.com/embed/E31V1K8sbsk"
        frameBorder="0"
        allowFullScreen
      ></Video>
      <PurpleBanner>
        <PairContainer>
          <ImageLink href={SoldierPdf} target="_blank">
            <Image src={SoldierImage} alt="PDF Image" />
          </ImageLink>
          <PairText>
            <BannerTitle>Soldier Magazine <br /> Brain Power<br /> PDF Download</BannerTitle>
          </PairText>
        </PairContainer>
        <PairContainer>
          <ImageLink href={Trial} target="_blank">
            <Image src={Trial} alt="PDF Image" />
          </ImageLink>
          <PairText>
            <BannerTitle>'Fly a drone by thought alone' <br /> report now available - contact us</BannerTitle>
          </PairText>
        </PairContainer>
        <PairContainer>
          <ImageLink href={NimbusPdf} target="_blank">
            <Image src={NimbusImage} alt="PDF Image" />
          </ImageLink>
          <PairText>
            <BannerTitle>Neurological Intelligent<br />Monitoring <br />PDF Download</BannerTitle>
          </PairText>
        </PairContainer>
      </PurpleBanner>
      <Title>Bloomberg news report - Mind Controlled Drone</Title>
      <Video
        src="https://www.bloomberg.com/media-manifest/embed/iframe?id=e7633699-b2b6-4fd6-8f61-6507c24a3f72"
        frameBorder="0"
        allowFullScreen
      ></Video>
    </Container>
  );
};

export default TechSection;
