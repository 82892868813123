import styled from "styled-components";
import { Link as LinkR } from "react-router-dom";
import projectsHero from "../../images/projectsHero.jpg"
import { MdKeyboardArrowRight, MdArrowForward } from "react-icons/md";

export const HeroContainer = styled.div`
  background: #000;
  background-image: url(${projectsHero}); // Set your background image URL
  background-size: cover; // Adjust the size of the background image
  background-repeat: no-repeat; // Prevent the image from repeating
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 30px;
  height: 650px;
  position: relative;
  z-index: 1;

  // Dims and Aligns video.
  :before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.2) 0%,
        rgba(0, 0, 0, 0.6) 100%
      ),
      linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, transparent 100%);
    z-index: 2;
  }
  ${(props) =>
    props.isFilterActive &&
    `
      background-color: rgba(0, 0, 0, 0); /* Adjust the opacity and color as needed */
      filter: grayscale(100%); /* Apply grayscale filter to make it grey */
    `}
`;

export const HeroBg = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

export const VideoBg = styled.video`
  width: 100%;
  height: 150%;
  -o-object-fit: cover;
  object-fit: cover;
  background: #232a34;
`;

export const HeroContent = styled.div`
  z-index: 3;
  max-width: 1200px;
  position: absolute;
  padding: 8px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const HeroH1 = styled.h1`
  color: #fff;
  font-size: 48px;
  text-align: center;

  @media screen and (max-width: 768px) {
    font-size: 40px;
  }
  @media screen and (max-width: 480px) {
    font-size: 32px;
  }
`;

export const HeroP = styled.p`
  margin-top: 24px;
  color: #fff;
  font-size: 24px;
  text-align: center;
  max-width: 600px;

  @media screen and (max-width: 768px) {
    font-size: 24px;
  }
  @media screen and (max-width: 480px) {
    font-size: 18px;
  }
`;

export const HeroBtnWraper = styled.div`
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const ArrowForward = styled(MdArrowForward)`
  margin-left: 8px;
  font-size: 20px;
`;
export const ArrowRight = styled(MdKeyboardArrowRight)`
  margin-left: 8px;
  font-size: 20px;
`;

export const Img = styled.img`
max-width: 100%;
max-height: 100%;
margin: auto;
`;

export const Button = styled(LinkR)`
  border-radius: 50px;
  background: #221F3C;
  white-space: nowrap;
  padding: ${({ big }) => (big ? "14px 48px" : "12px 30px")};
  color: ${({ dark }) => (dark ? "#fff" : "#fff")};
  font-size: ${({ fontBig }) => (fontBig ? "20px" : "16px")};
  outline: none;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  transition: all 0.2 ease-in-out;
  text-decoration: none;

  transition: transform 0.3s ease-in-out; /* Add transition for the hover effect */
  &:hover {
    transform: scale(1.2); /* Expand the icon on hover */
  }
  }
`;