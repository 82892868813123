import React, { useState } from "react";
import ScrollToTop from "../ScrollToTop";
import TalksSection from "../Talks Page Components/TalksSection";
import Footer2 from "../Footer Components/Footer2";
import Navbar2 from "../Nav Components/Navbar2";
import Sidebar2 from "../Sidebar2";
import TalksHero from "../Talks Page Components/TalksHero";
import { cyberTalks, droneTalks, } from "../Talks Page Components/TalksSection/TalksData";

const TalksPage = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  return (
    <>
    <Sidebar2 isOpen={isOpen} toggle={toggle} />
      <ScrollToTop />
      <Navbar2 toggle={toggle} />
      <TalksHero />
      <TalksSection  {...cyberTalks}/>
      <TalksSection  {...droneTalks}/>
      <Footer2 />
    </>
  );
};
export default TalksPage;
