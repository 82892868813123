import React, { useState } from "react";
import {
  HeroContainer,
  HeroContent,
  HeroH1,
  HeroBtnWraper,
  Button
} from "./TeamsHeroElements";

const TeamsHero = ({ isFilterActive }) => {
  const [hover, setHover] = useState(false);

  const onHover = () => {
    setHover(!hover);
  };

  return (
    <HeroContainer isFilterActive={isFilterActive} id="home">
      <HeroContent>
        <HeroH1>
        TEAMS
        </HeroH1>
        <HeroBtnWraper>
          <Button
            to={`/?scrollTo=${"contact"}`}
            onMouseEnter={onHover}
            onMouseLeave={onHover}
            primary="true"
            dark="true"
            smooth="true"
            duration={500}
            spy="true"
            exact="true"
            offset={-80}
          >
            Contact Us 
          </Button>
        </HeroBtnWraper>
      </HeroContent>
    </HeroContainer>
  );
};

export default TeamsHero;
