import React from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./components/pages";
import TrainingPage from "./components/pages/TrainingPage";
import TechPage from "./components/pages/TechPage";
import TalksPage from "./components/pages/TalksPage";
import FakeNewsPage from "./components/pages/FakeNewsPage";
import TeamsPage from "./components/pages/TeamsPage";
import ProjectsPage from "./components/pages/ProjectsPage";
import MeditationPage from "./components/pages/MeditationPage";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" exact element={<Home />} />
        <Route path="/training" element={<TrainingPage />} />
        <Route path="/tech" element={<TechPage />} />
        <Route path="/talks" element={<TalksPage />} />
        <Route path="/fakenews" element={<FakeNewsPage />} />
        <Route path="/teams" element={<TeamsPage />} />
        <Route path="/projects" element={<ProjectsPage />} />
        <Route path="/meditation" element={<MeditationPage />} />
      </Routes>
    </Router>
  );
}

export default App;
