import React, { useEffect, useState } from "react";
import { FaBars } from "react-icons/fa";
import { IconContext } from "react-icons";
import { animateScroll as scroll } from "react-scroll";
import Logo from "./../../logos/N8Logo.png"
import {
  FaInstagram,
  FaLinkedin,
} from "react-icons/fa";
import {
  Nav,
  NavbarContainer,
  NavLogoImage,
  MobileIcon,
  NavMenu,
  NavItem,
  NavLinks,
  NavBtn,
  NavBtnLink,
  NavBtnScroll,
  SocialIconLink,
} from "./NavbarElements";

const Navbar = ({ isVideoPlaying, toggleFilter, toggle, isFilterActive, buttonText }) => {
  const [scrollNav, setScrollNav] = useState(false);

  const changeNav = () => {
    if (window.scrollY > 80) {
      setScrollNav(true);
    } else {
      setScrollNav(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", changeNav);
  }, []);

  const toggleHome = () => {
    scroll.scrollToTop();
  };
  
  const TimerVideo = () => {
    if (isFilterActive) {
      const videoUrl = "https://www.youtube.com/embed/OTCD5fQI8q8?autoplay=1&mute=1"

      const iframeStyle = {
        marginTop: "80px",
      };

    return (
      <div>
       <iframe
         allow="autoplay; encrypted-media"
         width="280"
         height="157.5" 
         src={videoUrl}
         title="YouTube Video"
         frameBorder="0"
         allowFullScreen
         style={iframeStyle}
       ></iframe>
     </div>
     );
    }

return null;
};

  return (
    <IconContext.Provider  value={{ color: "#fff" }}>
      <Nav isFilterActive={isFilterActive} scrollNav={scrollNav}>
        <NavbarContainer>
        <NavLogoImage src={Logo} alt="NEURO8 Logo" onClick={toggleHome} />
          <NavBtn>
            <NavBtnScroll  
            to="contact"
            primary="true"
            dark="true"
            smooth="true"
            duration={500}
            spy="true"
            exact="true"
            offset={-80}>Contact Us
          </NavBtnScroll>
          </NavBtn>
          <MobileIcon onClick={toggle}>
            <FaBars />
          </MobileIcon>
          <NavMenu>
            <NavItem>
              <NavLinks
                to="about"
                smooth="true"
                duration={500}
                spy="true"
                exact="true"
                offset={-80}
              >
                Why
              </NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks
                to="discover"
                smooth="true"
                duration={500}
                spy="true"
                exact="true"
                offset={-80}
              >
                What
              </NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks
                to="services"
                smooth="true"
                duration={500}
                spy="true"
                exact="true"
                offset={-80}
              >
                How
              </NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks
                to="where"
                smooth="true"
                duration={500}
                spy="true"
                exact="true"
                offset={-80}
              >
                Where
              </NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks
                to="contact"
                smooth="true"
                duration={500}
                spy="true"
                exact="true"
                offset={-80}
              >
                Contact
              </NavLinks>
            </NavItem>
            <SocialIconLink href="https://www.linkedin.com/company/neuro8/" target="_blank" aria-label="Linkedin">
              <FaLinkedin />
            </SocialIconLink>
            <SocialIconLink href="https://www.instagram.com/neuro_8/" target="_blank" aria-label="Inst">
              <FaInstagram />
            </SocialIconLink>
          </NavMenu>
          <NavBtn >
            <NavBtnLink 
          onClick={toggleFilter} 
          >{buttonText}
          {isVideoPlaying && <TimerVideo />}
          </NavBtnLink>
          </NavBtn>
        </NavbarContainer>
      </Nav>
    </IconContext.Provider>
  );
};

export default Navbar;
