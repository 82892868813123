import styled from "styled-components";

export const Background = styled.div`
background: linear-gradient(108deg, rgba(3, 169, 244, 1) 0%, rgba(0, 68, 119, 1) 100%);
  
`;

export const Container = styled.div`

  display: flex;
  align-items: center;
  padding: 20px;
  padding-bottom: 90px;
  text-align: center;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

export const Content = styled.div`
  max-width: 40%;
  padding: 50px;

  @media screen and (max-width: 768px) {
    max-width: 100%;
    margin-top: 20px;
  }
`;

export const Video = styled.iframe`
  width: 100%;
  height: 50vh;
  padding-top: 90px;
  border-radius: 10px;

  @media screen and (max-width: 768px) {
    max-width: 100%;
  }
`;

export const Title = styled.h1`
  font-size: 24px;
  margin-top: 40px;
`;

export const Text = styled.p`
  color: #fff;
  font-size: 16px;
  margin-top: 10px;
  // text-align: left; 
`;


export const Text2 = styled.p`
  color: #000;
  font-size: 13px;
  margin-top: 20px; 
`;

export const ExternalLink = styled.a`
  text-decoration: none;
  font-weight: bold;
  cursor: pointer;
  margin: 0 5px;

  &:hover {
    text-decoration: underline;
  }
`;

export const TrainingSectionContainer = styled.div`
  background-color: #221F3C;  
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 22vh;
  padding-bottom: 22vh;

`;

export const TrainingsContainer = styled.div`
  display: flex;
  width: auto;
  max-width: 90%;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 70px;

  @media screen and (max-width: 1050px) {
    justify-content: center;
    display: grid;
    grid-template-columns: 1fr; 
    gap: 40px;
  }
`;

export const TrainingContainer = styled.div`
  border: 1px solid #e4e4e4;
  padding: 20px;
  text-align: center;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
  width: 70%;
  max-width: 400px;

  @media screen and (max-width: 1031px) {
    width: auto;
  }

  &:hover {
    transform: translateY(-5px);
  }
`;

export const TrainingHeader = styled.h2`
  font-size: 24px;
  margin-bottom: 10px;
  color: #000;
`;

export const TrainingTitle = styled.p`
  font-size: 18px;
  font-weight: bold;
  color: #0096FF;
`;

export const TrainingText = styled.p`
  font-size: 16px;
  margin-bottom: 10px;
  color: #555;
`;
export const BulletList = styled.ul`
  padding-top: 20px;
  text-align: left;
  padding-left: 50px;
  margin-bottom: 10px;
  

  @media screen and (max-width: 500px) {
    padding-left: 10px;
  }
`;
export const BulletListItem = styled.li`
font-size: 16px;
text-align: centre;
`;

export const ImageRowContainer = styled.div`
padding-top: 22vh;
padding-bottom: 22vh;
display: flex;
flex-wrap: wrap;
justify-content: space-around;

@media screen and (max-width: 920px) {
  justify-content: center;
  padding-top: 12vh;
  padding-bottom: 12vh;
}
`;

export const Image = styled.img`
width: 200px;
height: auto;
margin: 10px;
border-radius: 40px;

@media screen and (max-width: 920px) {
  width: 40%;
  margin: 45px;
  margin-top: 150px;
}
`;