import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { testimonials } from './testimonialData';

export const InfoContainer = styled.div`
  color: #fff;

  ${(props) =>
    props.isFilterActive &&
    `
      filter: grayscale(80%); /* Apply grayscale filter to make it grey */
    `}

`;

export const InfoWrapper = styled.div`
  display: grid;
  z-index: 1;
  width: 85%;
  max-width: 1100px;
  margin-right: auto;
  margin-left: auto;
  padding: 0 24px;
  justify-content: center;
  
  @media screen and (max-width: 768px) {
    height: 1200px;
  }
`;

export const InfoRow = styled.div`
  display: grid;
  grid-auto-columns: minmax(auto, 1fr);
  align-items: center;
  grid-template-areas: ${({ imgStart }) =>
    imgStart ? `'col1 col2'` : `'col2 col1'`};

    @media screen and (max-width: 768px) {
      grid-template-areas: ${({ imgStart }) =>
        imgStart ? `'col1 col1' 'col1 col1'` : `'col1 col1' 'col2 col2'`
    }
}`;
      
export const Column1 = styled.div`
  margin-bottom: 15px;
  padding: 0 15px;
  grid-area: col1;
`;

export const Column2 = styled.div`
  margin-bottom: 15px;
  padding: 0 15px;
  grid-area: col2;
`;

export const TextWrapper = styled.div`
  max-width: 540px;
  padding-top: 0;
  padding-bottom: 60px;
  
  @media screen and (max-width: 768px) {
    padding-bottom: 30px;
  }
`;

export const TopLine = styled.p`
  color: #D3000D;
  font-size: 16px;
  line-height: 16px;
  font-weight: 700;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  margin-bottom: 16px;
  
  @media screen and (max-width: 768px) {
    margin-bottom: 10px;
  }
`;

export const Heading = styled.h1`
  margin-bottom: 24px;
  font-size: 25px;
  line-height: 1.1;
  font-weight: 600;
  color: ${({ lightText }) => (lightText ? "#f7f8fa" : "#010606")};
  
  @media screen and (max-width: 768px) {
    margin-bottom: 20px;
  }
  
  @media screen and (max-width: 480px) {
    font-size: 32px;
  }
`;

export const Subtitle = styled.p`
  max-width: 440px;
  font-size: 18px;
  line-height: 24px;
  color: #000;
  
  @media screen and (max-width: 768px) {
    margin-bottom: 20px;
  }
`;


const TestimonialSliderWrapper = styled.div`
  height: 700px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  transition: opacity 0.5s ease-in-out;
  opacity: ${({ fade }) => (fade ? 0 : 1)};
  width: 90%; /* Full width for mobile */
  max-width: 400px; /* Maximum width for desktop */
  
  @media screen and (max-width: 768px) {
    align-items: flex-start;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const PersonInfo = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const PersonImage = styled.img`
  object-fit: contain; /* Maintain aspect ratio */
  width: 60px; /* Adjust size as needed */
  height: 60px; /* Adjust size as needed */
  margin-right: 10px;
  border-radius: 50%; /* Rounded image */
`;

const PersonText = styled.div`
  display: flex;
  flex-direction: column;
`;

const PersonName = styled.span`
  font-weight: bold;
  font-size: 16px;
  color: #555; /* Adjust color as needed */
`;

const CompanyName = styled.span`
  font-size: 14px;
  color: #555; /* Adjust color as needed */
`;

const CompanyLogo = styled.img`
  object-fit: contain; /* Maintain aspect ratio */
  width: 100%; /* Full width for mobile */
  max-width: 60%; /* Maximum width for desktop */
  margin-right: 0; /* Remove margin for mobile */
  margin-bottom: 20px; /* Add space between image and text for mobile */
  border-radius: 40px;
`;

const TestimonialText = styled.p`
  color: #000;
  font-size: 16px; /* Smaller font size for mobile */
  text-align: center; /* Center text for mobile */
  width: 100%; /* Full width for mobile */
`;

const ButtonsContainer = styled.div`
  display: flex;
`;

const ArrowButton = styled.button`
border-radius: 50px;
background: ${({ primary }) => (primary ? "#01bf71" : "#D3000D")};
white-space: nowrap;
padding: 12px 30px;
margin: 10px;
color: #fff;
font-size: 16px;
border: none;
cursor: pointer;
display: flex;
transition: all 0.2 ease-in-out;
text-decoration: none;

&:hover {
  transition: all 0.2 ease-in-out;
  background: ${({ primary }) => (primary ? "#D3000D" : "#01bf71")};
}
`; 

const BackButton = styled(ArrowButton)``;


const TestimonialSlider = ({}) => {  
  const [currentTestimonialIndex, setCurrentTestimonialIndex] = useState(0);
  const [fade, setFade] = useState(false);
  
  const prevTestimonial = () => {
    setFade(true); // Set fade to true before changing the index
    setTimeout(() => {
      setCurrentTestimonialIndex((prevIndex) =>
        prevIndex === 0 ? testimonials.length - 1 : prevIndex - 1
      );
      setFade(false); // Set fade back to false after changing the index
    }, 500); // Adjust the timing to match your transition duration
  };
   const nextTestimonial = () => {
    setFade(true); // Set fade to true before changing the index
    setTimeout(() => {
      setCurrentTestimonialIndex((prevIndex) =>
        prevIndex === testimonials.length - 1 ? 0 : prevIndex + 1
      );
      setFade(false); // Set fade back to false after changing the index
    }, 500); // Adjust the timing to match your transition duration
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setFade(true); // Fade out
      setTimeout(() => {
        setCurrentTestimonialIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
        setFade(false); // Fade in
      }, 500); // Adjust the timing to match your transition duration
    }, 5000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <>
    <InfoContainer id="where">
        <InfoWrapper>
          <InfoRow>
          <Column2>
    <TestimonialSliderWrapper fade={fade}>
      <ContentWrapper>
        <CompanyLogo
          src={testimonials[currentTestimonialIndex].companyLogo}
          alt="Company Logo"
        />
        <PersonInfo>
          <PersonImage
            src={testimonials[currentTestimonialIndex].personImage}
            alt="Person Image"
          />
          <PersonText>
            <PersonName>{testimonials[currentTestimonialIndex].personName}</PersonName>
            <CompanyName>{testimonials[currentTestimonialIndex].companyName}</CompanyName>
          </PersonText>
        </PersonInfo>
        <TestimonialText>{testimonials[currentTestimonialIndex].testimonial}</TestimonialText>
      </ContentWrapper>
    </TestimonialSliderWrapper>
            </Column2>
            <Column1>
              <TextWrapper>
                <TopLine>WHERE</TopLine>
                <Heading >Hear From Our Clients</Heading>
                <Subtitle>We love to our share success stories so here are a few of our clients and what they have said about us and our founder, Ellen.</Subtitle>
              </TextWrapper>
                 <ButtonsContainer>
                  <BackButton onClick={prevTestimonial}>&lt; Back</BackButton>
                  <ArrowButton onClick={nextTestimonial}>Next &gt;</ArrowButton>
                </ButtonsContainer>
            </Column1>
          </InfoRow>
        </InfoWrapper>
      </InfoContainer>
  </>
    
  );
};

export default TestimonialSlider;
