
// Import Profile images
import profile1 from "../../images/testimonials/ARWP.png"
import profile2 from "../../images/testimonials/KJRR.png"
import profile3 from "../../images/testimonials/MNES.png"
import profile4 from "../../images/testimonials/TWU.png"
import profile5 from "../../images/testimonials/SMG.png"
import profile6 from "../../images/testimonials/LMS.png"
import profile7 from "../../images/testimonials/SEW.png"
import profile8 from "../../images/testimonials/SWS.png"
import profile9 from "../../images/testimonials/JCJHM.png"
import profile10 from '../../images/testimonials/CS-DEVCOM.jpg';
import profile11 from '../../images/testimonials/PF.png';

// Import comapny logos
import logo1 from '../../images/testimonials/WorldPay.png';
import logo2 from '../../images/testimonials/RR.png';
import logo3 from '../../images/testimonials/EuroSport.png';
import logo4 from '../../images/testimonials/Ultra.png';
import logo5 from '../../images/testimonials/GM.png';
import logo6 from '../../images/testimonials/Sorce.png';
import logo7 from '../../images/testimonials/Westfields.png';
import logo8 from '../../images/testimonials/Serbus.png';
import logo9 from '../../images/testimonials/JHM.png';
import logo10 from '../../logos/USDEVCON.png';
import logo12 from '../../images/testimonials/UltraGroup.jpg';
import image01 from '../../images/testimonials/N8&Ultra2.jpg';
import CSimage from '../../images/testimonials/CSimageLogo.png';
import PFimage from '../../images/testimonials/PFimageLogo.png';

export const testimonials = [
    {
      personImage: profile1,
      companyName: 'Worldpay –  Director of Organisational Development',
      personName: 'Andrea Ranyard',
      companyLogo: logo1,
      testimonial: '"Ellen simply makes things happen"',
    },
    {
      personImage: profile2,
      companyName: 'Rolls-Royce Aerospace – Corporate Strategy',
      personName: 'Kelly Jones',
      companyLogo: logo2,
      testimonial: '"I am deeply grateful for the opportunity to work with Ellen as I prepared to leave Rolls-Royce Aerospace after 17 years and to become a CEO of two engineering businesses in the northeast. Ellen’s method of coaching me to tackle challenges in a positive way was refreshingly different from the many management courses I have been on over the years, It had a profoundly positive and direct impact on the results I achieved in my new role. Through her coaching, I became much more effective at engaging my teams and creating a more positive and productive work environment. The benefits of working with a truly authentic trainer, with clear insights gained from a deep understanding of her field, have been career changing for me."',
    },
    {
      personImage: profile3,
      companyName: 'EUROSPORT – TV Commentator & BTCC Championship Driver',
      personName: 'Matt Neal',
      companyLogo: logo3,
      testimonial: '"I\'ve always believed the mind and mind management is one of the most powerful tools a sports person can have in their armoury, why do some drivers, tennis players or whatever have that edge over their advisories? Taking fitness and practice as a given it\'s finding that advantage which is the secret ingredient we all crave, speaking with experience Ellen can be the key to that source breaking down habits creating new ones with positive goals and ways of thought. Why am I still able to stay competitive and still win now I\'m 50yrs old... I believe the answer is not leaving any stone unturned!"',
    },
    {
      personImage: profile4,
      companyName: 'Ultra PCS Ltd - Land Strategy Director',
      personName: 'Tony White',
      companyLogo: logo4,
      testimonial: '"Ellen delivered a very informative and engaging workshop to a varied set of participants. Everyone was impressed by the content and found the workshop extremely worthwhile. Ellen’s company is now a special project partner to Ultra Electronics on a unique BCI project called NIMBUS."',
    },
    {
      personImage: profile5,
      companyName: 'Givaudan – Head of MISTA',
      personName: 'Scott May',
      companyLogo: logo5,
      testimonial: '"This is a game changer"',
    },
    {
      personImage: profile6,
      companyName: 'SORCE – Chief Impact Officer',
      personName: 'Lisa May',
      companyLogo: logo6,
      testimonial: '"Ellen is results driven and passionate about helping people to improve their communications. She coaches communication skills, sharing practical tips and techniques. She will help you deliver your vision, design a strategy and work with you to create change both personally and professionally. She is passionate about unlocking human potential and as a result is a ‘Change your mind …. change your results’ (neuro-change solutions) trainer based on the latest research in neuro-science from the United States."',
    },
    {
      personImage: profile7,
      personName: 'Sean Edwards',
      companyName: 'Westfield Football Club',
      companyLogo: logo7,
      testimonial: '“Westfields are proud and honoured to be associated with Ellen Kay from NEURO8, creating a mindset that can deal with everyday challenges in business or as an individual can affect how we perform, they help individuals, companies, or teams to map a pathway to success, achieved through a variety of human factors and other neuroscience and technology led methods. Simply great.”',
    },
    {
      personImage: profile8,
      personName: 'Seb Wiles',
      companyName: 'SERBUS – CTO / Co-Founder',
      companyLogo: logo8,
      testimonial: '"Enjoyed the course. A full day and very thought provoking. Ellen was knowledgeable and used good examples to help my understanding."',
    },
    {
      personImage: profile9,
      companyName: 'Entrepeneur – Business Coach and Mentor',
      personName: 'John Cooper',
      companyLogo: logo9,
      testimonial: '"Ellen is an inspirational entrepreneur and neuro-science expert. She is able to get to the heart of what is troubling you and holding you back. If you\'re determined to grow, progress and succeed in business and in life, you need Ellen Kay on your team."',
    },
    {
      personImage: profile10,
      companyName: 'Director DEVCOM - Atlantic (DFE)',
      personName: 'Charles Seaberry MBA',
      companyLogo: CSimage,
      testimonial: '"Today was one of those days that remind me why I absolutely love my job. I had the incredible opportunity to engage with a group of brilliant minds, each one passionately committed to advancing technology. Our focus? The fascinating world of the mind. It\'s truly inspiring to be surrounded by individuals who are not just smart, but also deeply curious about the potential of the human mind and technology. The discussions we had were not only enlightening but also filled with innovative ideas that could pave the way for ground-breaking advancements. I\'m reminded once again of the power of collaboration and the beauty of sharing knowledge. Here\'s to many more such engaging and intellectually stimulating days! "',
    },
    {
      personImage: profile11,
      companyName: 'US Army Tech Scout for Europe, Africa, and the Middle East',
      personName: 'Patrick Fowler, PhD ',
      companyLogo: PFimage,
      testimonial: '“What a great week with NEURO8 and Ultra PCS examining how more mindful users can better employ emerging technology. When we put Warfighter wellbeing first, might we actually have a more resilient and more capable force? Great job Ellen G Kay BA Hons MA, TONY WHITE and Helen Austin.”',
    },
    {
      personImage: logo4,
      companyName: 'Providing safety and mission critical solutions in the air and on the ground. Defense and Space Manufacturing',
      personName: 'Ultra PCS',
      companyLogo: logo12,
      testimonial: '“What a great week with NEURO8 and Ultra PCS examining how more mindful users can better employ emerging technology. When we put Warfighter wellbeing first, might we actually have a more resilient and more capable force? Great job Ellen G Kay BA Hons MA, TONY WHITE and Helen Austin.”',
    },
    {
      personImage: profile4,
      companyName: 'Ultra PCS Ltd - Land Strategy Director',
      personName: 'Tony White',
      companyLogo: image01,
      testimonial: '“Despite a wet and windy week, we had a great time trialing our NIMBUS system out of the office....great work team. At Ultra PCS we are taking further strides with our UltraNIMBUS project. This technology is a state-of-the-art wearable sensor that enables the controlling of systems by thought alone. We\'ve been testing in a representative environment with people from the user community (not pictured) and the initial results are looking promising. More news to follow later in the year. Ellen Kay - NEURO8 and Helen Austin - Ultra”',
    },
  ];