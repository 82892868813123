import React from 'react';
import styled from 'styled-components';
import alpha from "../../images/AlphaTeam.jpg";
import bravo from "../../images/BravoTeam.png";
import charlie from "../../images/CyberTeam.jpg";
import PDF from "../../files/NEURO8.pdf"

const SectionContainer = styled.div`
  height: 100%;
  width: auto;
  padding: 30px;
  background: linear-gradient(108deg, rgba(3, 169, 244, 1) 0%, rgba(0, 68, 119, 1) 100%);
`;

const ProfilesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin-top: 50px;
  justify-content: flex-start; // Align items to the top

  @media screen and (min-width: 1031px) {
    flex-direction: row;
    justify-content: center; // Center items horizontally
    align-items: flex-start; // Align items to the top
  }
`;

const ProfileContainer = styled.div`
  border: 1px solid #e4e4e4;
  padding: 20px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
  width: 90%;
  margin: 0 auto;
  max-width: 400px;

  &:hover {
    transform: translateY(-5px);
  }

  @media screen and (min-width: 1031px) {
    width: 90%;
    max-width: 400px;
  }
`;
const ImageText = styled.p`
  font-size: 18px;
  margin-bottom: 15px;
  color: #555;
  font-weight: bold;

`;
const ProfileHeader = styled.h2`
  font-size: 24px;
  text-align: center; 
  margin-bottom: 10px;
  color: #000;
`;

const ProfileTitle = styled.p`
  font-size: 18px;
  font-weight: bold;
  color: #000;
`;

const ProfileText = styled.p`
  font-size: 16px;
  margin-bottom: 15px;
  color: #555;

  a {
    color: #fff;
    background-color: #0096FF;
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    text-decoration: none;
    display: inline-block;
    margin-top: 5px;

    &:hover {
      background-color: #0076CC;
    }
  }
`;

const ProfileImage = styled.img`
  width: 100%;
  height: auto;
  margin-top: 10px;
`;
export const PurpleBar = styled.div`
  background-color: #221F3C;  
  width: 100%;
  height: 5px;
  padding-top: 0px;
  padding-bottom: 0px;  
  margin-bottom: 20px;
  margin-top: 0px;
  display: flex;
`;

const CompanyProfiles = () => {
  return (
    <SectionContainer>
      <ProfilesContainer>
          <ProfileContainer>
            <ProfileHeader>Alpha</ProfileHeader>
            <ProfileImage src={alpha}/>
            <ImageText>Team Alpha delivers our licensed neuroscience based training programme. Led by Ellen Kay, the founder of NEURO8.
            </ImageText>
            <br/>
            <PurpleBar style={{marginTop:"20px"}}/>
            <ProfileTitle>Team Lead</ProfileTitle>
            
            <ProfileText>Ellen’s career has seen her working with corporate clients, high performance athletes and the defence and security sector. With one common theme - optimising human performance by unlocking potential and improving communication from the inside out.</ProfileText>
            
            <ProfileText>Due to this passion for personal development, she qualified as a licensed NCS consultant in 2016, allowing her to deliver the neuroleadership program researched, documented, and created by Dr. Joe Dispenza. She has access to over 200 consultants to deliver the change program globally from West to East – from San Francisco and Mexico to the UK and Europe, Singapore, and Australia. <br />
        <a href="training">LEARN MORE</a></ProfileText>

        <ProfileText>The following year she founded NEURO8 and appeared at the first UK Special Forces Defence Expo. She went on to become a special project partner with Ultra Ltd, creating UltraNIMBUS ‘thought control technology’. And they have demonstrated, live on stage, how to ‘fly a drone by thought alone’. <br />
        <a href="tech">LEARN MORE</a></ProfileText>

        <ProfileText>For more details of Ellen’s career and research partners click here <br />
        <a href={PDF} target="_blank">LEARN MORE</a></ProfileText>

          </ProfileContainer>


          <ProfileContainer>
            <ProfileHeader>Bravo</ProfileHeader>
            <ProfileImage src={bravo}/>
            <ImageText>Team Bravo delivers secure communication solutions delivered by a team of ex-special forces personnel and led by S the co-founder of our Fake News offering.
            </ImageText>
            <br/>
            <PurpleBar />
            <ProfileTitle>Team Lead</ProfileTitle>
            <ProfileText>S is ex UK Special Forces passionate, dynamic, and driven. With strong values and exceptional leadership, he delivers industry-leading strategies and capabilities constantly striving to be the best, He provides specialist secure communications and cyber fusion solutions that create safer communities. He has specialist skills in collating products, services, and people to produce a packaged ‘solution’ in various sectors. Areas include conservation, government, and counter human trafficking. </ProfileText>
            <ProfileTitle>R</ProfileTitle>
            <ProfileText>R is a former communications specialist in the UK Special Forces. He now provides cyber security consultancy and investigation services using open-source intelligence (OSINT). He provides forensic reports for use in court to assist in asset recovery for victims of online fraud. He also provides training and consultancy to ensure our clients do not fall victim to online targeting or fraud by ensuring their digital footprint is safe and secure. </ProfileText>
            <ProfileTitle>C</ProfileTitle>
            <ProfileText>C is a consummate professional, with an enthusiasm and drive to support any project delivering the highest possible standards to successful outcomes. With almost twenty years’ experience, working at the highest levels of national security, and almost ten years delivering direct support and guidance to the maritime and land-based security sectors, he has become a prominent point of contact for many UHNW and organisations. </ProfileText>
          </ProfileContainer>
          
          <ProfileContainer>
            <ProfileHeader>Charlie</ProfileHeader>
            <ProfileImage src={charlie}/>
            <ImageText>Team Charlie offers cyber security consultancy and AWS security solutions.
            </ImageText>
            <PurpleBar style={{marginTop:"90px"}}/>
            <ProfileTitle>Cyber</ProfileTitle>
            <br/>
            <ProfileTitle>P</ProfileTitle>
            <ProfileText>P is a Cyber Security Consultant, Bug Bounty Hunter, Secure Web Developer, Social Engineer working within private and public sectors.</ProfileText>
            <ProfileTitle>F</ProfileTitle>
            <ProfileText>F is a Cyber Security Consultant, Ethical Hacker, Internet Governance, Researcher and Lecturer.</ProfileText>
            <ProfileTitle>Tech</ProfileTitle>
            <br/>
            <ProfileTitle>K</ProfileTitle>
            <ProfileText>K is an Ex Special Forces Signals. Technical Consultant with 27 certifications including OSCP, PNPT, CISSP and AWS. With 8 years as a British Army systems engineer with 10 years technical consultancy.</ProfileText>
            <ProfileTitle>M</ProfileTitle>
            <ProfileText>M is an AWS Certified Security Consultant</ProfileText>
            </ProfileContainer>
      </ProfilesContainer>
    </SectionContainer>
  );
};

export default CompanyProfiles;
