import React, { useEffect }  from "react";
import { useLocation } from 'react-router-dom';
import { animateScroll as scroll, scroller } from "react-scroll";
import TC from "../../files/N8TC.pdf"
import GDPR from "../../files/NEURO8GDPR.pdf"
import PP from "../../files/NEURO8PrivacyPolicy.pdf"
import Logo from "./../../logos/N8Logo.png"
import SecuredBy from "../SecuredBy";
import {
  FaInstagram,
  FaLinkedin,
} from "react-icons/fa";
import {
  FooterContainer,
  NavLogoImage,
  FooterWrap,
  FooterLinkContainer,
  FooterLinksWrapper,
  FooterLinkItems,
  FooterLinkTitle,
  FooterLink,
  SocialMedia,
  SocialMedialWrap,
  SocialLogo,
  WebsiteRights,
  SocialIcons,
  SocialIconLink,
  FooterScroll,
  FooterA,
  CompanyInfo
} from "./FooterElements";

const Footer = ({isFilterActive}) => {
  const location = useLocation();
  const toggleHome = () => {
    scroll.scrollToTop();
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const sectionToScroll = params.get('scrollTo');

    if (sectionToScroll) {
      scroller.scrollTo(sectionToScroll, {
        smooth: true,
        duration: 500,
      });
    }
  }, [location.search]);

  return (
    <FooterContainer isFilterActive={isFilterActive}>
      <FooterWrap>
        <FooterLinkContainer>
          <FooterLinksWrapper>
            <FooterLinkItems>
              <FooterLinkTitle>Site Map</FooterLinkTitle>
              <FooterScroll 
                to="about"
                smooth="true"
                duration={500}
                spy="true"
                exact="true"
                offset={-80} >Why</FooterScroll>
              <FooterScroll 
                to="discover"
                smooth="true"
                duration={500}
                spy="true"
                exact="true"
                offset={-80}  >Who</FooterScroll>
              <FooterScroll 
                 to="services"
                smooth="true"
                duration={500}
                spy="true"
                exact="true"
                offset={-80}>How</FooterScroll>
              <FooterScroll 
                to="where"
                smooth="true"
                duration={500}
                spy="true"
                exact="true"
                offset={-80}>Where</FooterScroll>
              <FooterScroll 
              to="contact"
                smooth="true"
                duration={500}
                spy="true"
                exact="true"
                offset={-80}  >Contact</FooterScroll>
            </FooterLinkItems>
            <FooterLinkItems>
              <FooterLinkTitle>How</FooterLinkTitle>
              <FooterLink to="/tech">Nimbus</FooterLink>
              <FooterLink to="/training">Training</FooterLink>
              <FooterLink to="/fakenews">Fake News</FooterLink>
              <FooterLink to="/talks">Talks</FooterLink>
              <FooterLink to="/teams">Teams</FooterLink>
              <FooterLink to="/projects">Projects</FooterLink>
              <FooterLink to="/meditation">Meditation</FooterLink>
            </FooterLinkItems>
          </FooterLinksWrapper>
          <FooterLinksWrapper>
            <FooterLinkItems>
              <FooterLinkTitle>Accreditations </FooterLinkTitle>
              <FooterA href="https://neurochangesolutions.com" target="_blank" >NeuroChangeSolutions </FooterA>
              <FooterA href="https://www.heartmath.com" target="_blank" >HeartMath</FooterA>
              <FooterA href="https://www.mdx.ac.uk" target="_blank" >BA Hons / MA </FooterA>
              <FooterA href="https://iuk.ktn-uk.org/projects/cyberasap/cybermind/" target="_blank" >CyberASAP</FooterA>
              <FooterA href="https://www.plexal.com/cyber-runway-cohort-two" target="_blank" >Cyber Runway GROW </FooterA>
            </FooterLinkItems>
            <FooterLinkItems>
              <FooterLinkTitle>Legals</FooterLinkTitle>
              <FooterLink to={TC} target="_blank" >Terms and Conditions</FooterLink>
              <FooterLink to={PP} target="_blank" >Privacy Policy</FooterLink>
              <FooterLink to={GDPR} target="_blank" >GDPR</FooterLink>
              </FooterLinkItems>
          </FooterLinksWrapper>
        </FooterLinkContainer>
        <SocialMedia>
          <SocialMedialWrap>
            <SocialLogo to="/" onClick={toggleHome}>
            <NavLogoImage src={Logo} alt="NEURO8 Logo" />
            </SocialLogo>
            <WebsiteRights>
              NEURO8 {new Date().getFullYear()} All Rights Reserved
            </WebsiteRights>
            <SocialIcons> 
              <SocialIconLink href="https://www.linkedin.com/company/neuro8/" target="_blank" aria-label="Linkedin">
                <FaLinkedin /> 
              </SocialIconLink>
            <SocialIconLink href="https://www.instagram.com/neuro_8/" target="_blank" aria-label="Inst">
              <FaInstagram />
            </SocialIconLink>
            </SocialIcons>
          </SocialMedialWrap>
        </SocialMedia>
            <CompanyInfo>Registered in England and Wales | Company Registration No: 09480052 </CompanyInfo>
            <CompanyInfo>282 Lichfield Road, Sutton Coldfield, England, B74 2UG</CompanyInfo>
            <SecuredBy />
            </FooterWrap>
    </FooterContainer>
  );
};

export default Footer;
