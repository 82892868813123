import cyberImg from "../../images/cyberTalksImg.png"
import droneImg from "../../images/droneTalksImg.png"

export const cyberTalks = {
    id: "cyberTalks",
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: "CYBER TECH TALK",
    headline1: "RISK//DE-RISK",
    description1b: "“95% of cyber-crime is due to human error”. World Economic Forum",
    description2: "In this talk we share insights on how to de-risk your people, processes and technology. We discuss employment law and supply chain contracts, we look at how and why a hacker will target your organisation and finally we use two high profile case studies showing why a cyber-attack can close your organisation or win you multiple new contracts.",
    description3: "• People are your first line of defence. In a world of increased stress and cognitive overload you could be inadvertently creating your greatest 'insider threat'.",
    description4: "• Processes are your first line of defence. Sometimes in life you should dance like there is no-one watching but always write an email like you at an employment tribunal.",
    description5: "• Technology is your first line of defence. In a world of hidden warfare where cyber-crime and fraud are at an all-time high - don't just click on the link.",
    description6: "Hear first hand from our founder and our delivery partners: top 100 law firm, certified cyber consultant and ethical hacker on how to make cyber you new best friend.",
    buttonLabel: "Book Now",
    imgStart: true,
    image: cyberImg,
    alt: "A Set of three images of people talking",
    dark: true,
    primary: true,
    darkText: false,
    showButton: true,
    buttonLink: "`/?scrollTo=${'contact'}`}",
  };

  
export const droneTalks = {
    id: "droneTalks",
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: "MIND//DRONE KEYNOTE",
    headline1: "“FLY A DRONE BY THOUGHT ALONE”",
    description1: "When you improve command and control of technology and make your thoughts more repeatable and reliable then you not only improve performance but also wellbeing. And how do we know? Well, we measured it.",
    description2: "In this inspiring talk you will see how human intelligence works with artificial intelligence. Looking at UltraNIMBUS the neurological intelligent and brain utilisation system which was trialed by the US military in 2023.",
    description3: "In 2017 Tony White (Land Strategy Director at Ultra PCS Ltd) met Ellen Kay (Founder of NEURO8) at the first UK Special Forces Defence Expo. After sharing insights on neuroscience, software engineering and drone technology they created a ten year vision - to ‘fly a drone by thought alone’.",
    description4: "Hear ‘how’ and ‘why’ their vision has become a reality.",
    buttonLabel: "Book Now",
    imgStart: false,
    image: droneImg,
    alt: "A set of three images of Ellen",
    dark: false,
    primary: false,
    darkText: true,
    showButton: true,
    buttonToPDF: false,
    buttonLink: "contact",
    showTestimonialButton: false,
  };