import React, { useEffect, useState } from "react";
import { FaBars } from "react-icons/fa";
import { IconContext } from "react-icons";
import { animateScroll as scroll } from "react-scroll";
import Logo from "./../../logos/N8Logo.png"
import {
  Nav,
  NavbarContainer,
  NavLogoImage,
  MobileIcon,
  NavMenu,
  NavItem,
  NavLinks,
} from "./NavbarElements";

const Navbar2 = ({ toggle }) => {
  const [scrollNav, setScrollNav] = useState(false);

  const changeNav = () => {
    if (window.scrollY > 80) {
      setScrollNav(true);
    } else {
      setScrollNav(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", changeNav);
  }, []);

  const toggleHome = () => {
    scroll.scrollToTop();
  };

  return (
    <IconContext.Provider  value={{ color: "#fff" }}>
      <Nav  scrollNav={scrollNav}>
        <NavbarContainer>
        <NavLogoImage src={Logo} alt="NEURO8 Logo" onClick={toggleHome} />
          <MobileIcon onClick={toggle}>
            <FaBars />
          </MobileIcon>
          <NavMenu>
            <NavItem>
              <NavLinks
                to="/"
              >
                Home
              </NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks
                to="/tech"
              >
                Nimbus
              </NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks
                to="/training"
              >
                Training
              </NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks
                to="/fakenews"
              >
                Fake News
              </NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks
                to="/talks"
              >
                Talks
              </NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks
                to="/teams"
              >
                Teams
              </NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks
                to="/projects"
              >
                Projects
              </NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks
                to="/meditation"
              >
                Meditation
              </NavLinks>
            </NavItem>
          </NavMenu>
        </NavbarContainer>
      </Nav>
    </IconContext.Provider>
  );
};

export default Navbar2;
