import React from "react";
import styled from "styled-components";
// List of logos
import sdsc from "../../logos/SDSC.png"
import ultra from "../../logos/Ultra.png"
import USDevcon from "../../logos/USDEVCON.png"
import bloom from "../../logos/Bloomberg.png"

// Array of logo image paths or URLs
const logoData = [
    { src:sdsc, width: 250, height: 100 },
    { src: bloom, width: 250, height: 100 },
    { src:ultra, width: 250, height: 100 },
    { src:USDevcon, width: 250, height: 100 },
  ];

const LogoGroupWrapper = styled.div`
  display: grid;
  margin: 50px;
  grid-template-columns: repeat(4, 1fr); /* Create 3 columns */
  gap: 30px; /* Increase the spacing between logos */
  justify-items: center; /* Center logos horizontally within each column */

  
  ${(props) =>
    props.isFilterActive &&
    `
      background-color: rgba(0, 0, 0, 0); /* Adjust the opacity and color as needed */
      filter: grayscale(100%); /* Apply grayscale filter to make it grey */
    `}

    @media screen and (max-width: 1190px) {
      margin: 20px; /* Reduce the margin for mobile screens */
      grid-template-columns: repeat(2, 1fr); /* Display 1 columns on mobile */
      gap: 5px; /* Reduce the spacing between logos for mobile */
    }
`;

const LogoImage = styled.img`
  max-width: 100%; /* Adjust the maximum width to fit within the column */
  max-height: 100%; /* Adjust the maximum height to fit within the column */
  width: auto;
  height: auto;
  object-fit: contain; /* Maintain the original aspect ratio and contain within the dimensions */
  
  transition: transform 0.3s ease-in-out; /* Add transition for the hover effect */

  &:hover {
    transform: scale(1.2); /* Expand the icon on hover */
  }
  `;

const TechLogoGroup = ({isFilterActive}) => {
  return (
    <LogoGroupWrapper isFilterActive={isFilterActive}>
      {logoData.map((logo, index) => (
        <StyledLogoImage
          key={index}
          src={logo.src}
          alt={`Logo ${index + 1}`}
          width={logo.width}
          height={logo.height}
        />
      ))}
    </LogoGroupWrapper>
  );
};

const StyledLogoImage = styled(LogoImage)`
  width: ${(props) => props.width}px; /* Custom width for specific logos */
  height: ${(props) => props.height}px; /* Custom height for specific logos */
`;

export default TechLogoGroup;