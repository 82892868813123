import styled from "styled-components";
import bgImg from "./../../images/servicesbg.jpg"
import { Link as LinkR } from "react-router-dom";

export const ServicesContainer = styled.div`
  // padding-bottom: 40px;
  width: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;  /* Center the content horizontally */
  background-image: url(${bgImg});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  ${(props) =>
    props.isFilterActive &&
    `
      background-color: rgba(0, 0, 0, 0.5);
      filter: grayscale(100%);
    `}

  @media screen and (max-width: 768px) {
    background-color: #221F3C;
    background-image: none;
  }
`;
export const TopPurpleBanner = styled.div`
  background-color: #221F3C;  
  width: 90%;
  padding: 30px;  /* Adjust the padding as needed */
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;

  @media screen and (max-width: 768px) {
    width: auto;
  }
`;

export const BottomPurpleBanner = styled.div`
  background-color: #221F3C;   
  width: 90%;
  padding: 10px;  /* Adjust the padding as needed */
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  
  @media screen and (max-width: 768px) {
    width: auto;
  }
`;

export const ServicesWrapper = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  grid-gap: 80px;
  padding: 0 50px;

  @media screen and (max-width: 1000px) {
    grid-template-columns: 1fr 1fr;
  }
  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    padding: 0 20px;
  }
`;

export const ServicesCard = styled.div`
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px;
  max-height: 340px;
  padding: 40px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease-in-out;

  &:hover {
    transform: scale(1.02);
    transition: all 0.2 ease-in-out;
    cursor: pointer;
  }
`;

export const ServicesButton = styled(LinkR)`
  background: #01bf71;
  text-decoration: none;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  transition: background 0.2s ease-in-out;
  border-radius: 15px;

  &:hover {
    background: #D3000D;
    color: #fff;
  }

  @media screen and (max-width: 480px) {
    /* Adjust the button's padding, font size, or any other styles for smaller screens */
    padding: 8px 16px; /* Example padding for smaller screens */
    font-size: 0.9rem; /* Example font size for smaller screens */
  }
`;

export const ServicesIcon = styled.img`
  height: 160px;
  width: 160px;
  margin-bottom: 10px;
`;
export const ServicesH1 = styled.h1`
  color: #D3000D;
  font-size: 26px;
  line-height: 16px;
  font-weight: 700;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  text-align: center;  /* Center the text horizontally */

  @media screen and (max-width: 768px) {
    margin-bottom: 10px;
  }
`;
export const ServicesH2 = styled.h2`
  font-size: 1rem;
  margin-bottom: 10px;
`;
export const ServicesP = styled.p`
  font-size: 1rem;
  text-align: center;
`;
