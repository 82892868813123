import React from "react";
import styled from "styled-components";
import ContactForm from "../../ContactForm/index"
import BWaves from "../../images/BrainWaves.png"

const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 20px;
  padding-bottom: 90px;
  text-align: center;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

const Background= styled.div`
background: linear-gradient(108deg, rgba(3, 169, 244, 1) 0%, rgba(0, 68, 119, 1) 100%);
  
`;

const Content = styled.div`
  max-width: 40%;
  padding: 50px;

  @media screen and (max-width: 768px) {
    max-width: 100%;
    margin-top: 20px;
  }
`;

const Video = styled.iframe`
  width: 100%;
  height: 50vh;
  padding-top: 90px;
  border-radius: 10px;

  @media screen and (max-width: 768px) {
    max-width: 100%;
  }
`;

const Title = styled.h1`
  font-size: 24px;
  margin-top: 40px;
`;

const Text = styled.p`
  color: #fff;
  font-size: 16px;
  margin-top: 10px;
  // text-align: justify; 
`;

const TrainingSectionContainer = styled.div`
  background-color: #221F3C;  
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 22vh;
  padding-bottom: 22vh;

`;

const TrainingsContainer = styled.div`
  display: flex;
  width: auto;
  max-width: 90%;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 70px;

  @media screen and (max-width: 1050px) {
    justify-content: center;
    display: grid;
    grid-template-columns: 1fr; 
    gap: 40px;
  }
`;

const TrainingContainer = styled.div`
  border: 1px solid #e4e4e4;
  padding: 20px;
  align-items: center;
  text-align: center;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
  width: 70%;
  max-width: 400px;

  @media screen and (max-width: 1031px) {
    width: auto;
  }
  &:hover {
    transform: translateY(-5px);
  }
`;

const TrainingHeader = styled.h2`
  font-size: 24px;
  margin-bottom: 10px;
  color: #000;
`;

const TrainingTitle = styled.p`
  font-size: 18px;
  font-weight: bold;
  color: #0096FF;
`;

const TrainingText = styled.p`
font-size: 16px;
margin-bottom: 10px;
color: #555;
text-align: justify;


a {
  color: #fff;
  background-color: #0096FF;
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  text-decoration: none;
  display: inline-block;
  margin-top: 5px;

  &:hover {
    background-color: #0076CC;
  }
}
`;

const ContactFormContainer = styled.div`
  height: 1150px;
  display: grid;
  justify-content: centre; 
  align-items: center;
  
`;
export const PurpleBar = styled.div`
  background-color: #221F3C;  
  width: 100%;
  height: 5px;
  padding-top: 0px;
  padding-bottom: 0px;  
  margin-bottom: 20px;
  margin-top: 20px;
  display: flex;
`;

const ImageContainer = styled.div`
justify-content: center;
`;

const Image = styled.img`
max-width: 100%;
margin-top: 20px;
`;

const TrainingsData = [
  {
    name: "The Meditation Lens",
    description: (<>What is meditation? Well, if you can imagine your mind has a lens, like a camera, then you can use meditation to create a better picture. Being clear and specific about what we want is the key to creating what we want. If we do not focus and use a clear lens then our mind does not have the information necessary to recognise an opportunity even when its right in front of us.  <br /><br />
    Take the time to clearly define what you want, elevate your emotional response, and mentally rehearse the outcome. Remember it’s like learning to drive a car you will require a lot of lessons, which we call guided meditations. Then you need lots of driving experience to improve, which we call daily practise. <br /> <br />
    Our mind is the vehicle that allows us to fully experience this 3-D reality we call life, so it is up to us to direct this “mind” in the direction we want to travel. Learn to think greater than your environment, body and time and practise daily to create a whole new personal reality.<br /><br /></>),
  },
  {
    name: "Brainwaves",
    image: BWaves,
  },
  {
    name: "MAP Meditation",
    description: (<>To create a daily meditation practice requires not just motivation but discipline. Training your mind requires you to train your body. So, when we say you have to STOP to START what we want you to do is stop what you normally do, go still, and start a new routine. But your body will try to stop you as it won’t want you to change.<br/> <br />Imagine you are driving a formula one racing car. No driver ever wins the race without stopping in the pit lane. They change tyres, check brakes but the driver also has time to re-calibrate. It is a daily pit stop that we want you to create in your daily life to give you chance to change and grow.<br/> <br /> So, we have incorporated theta brain waves, with Ellen’s voice, in a guided meditation allowing you to stop in the pit lane and become your own pathfinder. You are able to navigate a new path on a new map to start a new journey. This 20 minute guided meditation will help you become aware of your unconscious thoughts, behaviours and emotions and help you create a new road map for life. <br/><br />Complete the form below to receive your FREE 20 minute download<br /></>),
  },
];

const MeditationSection = () => {
  return (<>
    <Background>
    <Container>
        <Video
          src="https://www.youtube.com/embed/ojhC4FRUHDs?si=P1QIDw-J3QKKD5Wn"
          frameBorder="0"
          allowFullScreen
        ></Video>
      <Content>
      <Title>‘MAP’ Meditation</Title>
      <Text>
      We have incorporated theta brain waves with Ellen’s voice in a guided meditation allowing you to become your own pathfinder. You are able to navigate a new path on a new map to start a new journey. This 20 minute guided meditation will help you become aware of your unconscious thoughts, behaviours and emotions that keep you travelling along that old familiar path and will help you take the road less travelled and create a new road map. It can be used for a project, goal, career change and much more.
      </Text>
      </Content>
    </Container>
    <TrainingSectionContainer>
          <TrainingsContainer>
          {TrainingsData.map((project, index) => (
            <TrainingContainer key={index}>
              <TrainingHeader>{project.name}</TrainingHeader>
              <PurpleBar />
                  <ImageContainer>
                      <Image src={project.image}/>
                  </ImageContainer>
                <TrainingTitle>{project.subtitle}</TrainingTitle>
          <TrainingText>{project.description}</TrainingText>
          <TrainingText>{project.description2}</TrainingText>
          <TrainingText>{project.description3}</TrainingText>
            </TrainingContainer>
          ))}
        </TrainingsContainer>
        
    </TrainingSectionContainer>
      <ContactFormContainer>
      <Title style={{ textAlign: "center", marginTop: "100px", marginBottom: "-100px" }}>Complete the form for your FREE download</Title>
          <ContactForm />
      </ContactFormContainer>
    </Background>
    </>);
};

export default MeditationSection;
