import React from "react";
import styled from "styled-components";
import bgImg from "../../images/contactbg.jpg"
import ContactForm from "../../ContactForm"

const Container = styled.div`
background-image: url(${bgImg}); // Set the background image here
background-size: cover; // Adjust the size of the background image
background-repeat: no-repeat; // Prevent the image from repeating
background-position: center; // Center the background image
`;


const HomeContactForm = ({ isFilterActive }) => {
  return (
    <Container>
        
      <ContactForm isFilterActive={isFilterActive} />
    </Container>
  );
};

export default HomeContactForm;