import styled from "styled-components";
import { Link } from "react-router-dom";

export const Container = styled.div`
height: 860px;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
padding-right: 40px;

${(props) =>
  props.isFilterActive &&
  `
    background-color: rgba(0, 0, 0, 0.5); /* Adjust the opacity and color as needed */
    filter: grayscale(100%); /* Apply grayscale filter to make it grey */
  `}
  
@media screen and (max-width: 768px) {
  height: 1100px;
}
@media screen and (max-width: 480px) {
  height: 1100px;
}
`;

export const FromWrap = styled.div`
  height: 100%;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media screen and (max-width: 400px) {
    height: 80%;
  width: 60%;
  }
`;

export const Icon = styled(Link)`
  margin-left: 32px;
  margin-top: 32px;
  text-decoration: none;
  color: #fff;
  font-weight: 700;
  font-size: 32px;

  @media screen and (max0width: 480px) {
    margin-left: 16px;
    margin-top: 8px;
  }
`;

export const FormContent = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media screen and (max-width: 480px) {
    padding: 10px;
  }
`;

export const Form = styled.form`
  background: #221F3C;
  max-width: 400px;
  height: auto;
  width: 100%;
  z-index: 1;
  display: grid;
  margin: 0 auto;
  padding: 40px 32px;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.9);

  @media screen and (max-width: 400px) {
    padding: 32px 32px;
  }
`;

export const FormH1 = styled.h1`
color: #D3000D;
font-size: 16px;
line-height: 16px;
font-weight: 700;
letter-spacing: 1.4px;
text-transform: uppercase;
margin-bottom: 30px;


@media screen and (max-width: 400px) {
  padding-left: 0px;
  
}
@media screen and (max-width: 768px) {
  padding-left: 0px;
}
  // margin-bottom: 40px;
  // color: #fff;
  // font-size: 20px;
  // font-weight: 400;
  // text-align: center;
`;

export const FormLabel = styled.label`
  margin-bottom: 8px;
  font-size: 14px;
  color: #fff;
`;

export const FormInput = styled.input`
  padding: 16px 16px;
  margin-bottom: 32px;
  border: none;
  border-radius: 4px;

  @media screen and (max-width: 650px) {
    width: 80%;
  }

  @media screen and (max-width: 450px) {
    width: 70%;
  }
`;

export const FormButton = styled.button`
  background: #01bf71;
  padding: 16px 0;
  border: none;
  color: #fff;
  font-size: 20px;
  border-radius: 4px;
  cursor: pointer;
  
  transition: transform 0.3s ease-in-out; /* Add transition for the hover effect */

  &:hover {
    transform: scale(1.05); /* Expand the icon on hover */
  }
`;

export const Text = styled.span`
  text-align: center;
  margin-top: 24px;
  color: #fff;
  font-size: 14px;
`;

export const SuccessMessage = styled.p`
  color: green;
  text-align: center;
  font-size: 16px;
  margin: 20px;
`;

export const ErrorMessage = styled.p`
  color: red;
  text-align: center;
  font-size: 16px;
  margin: 20px;
`;